// Generated by Framer (4ba8877)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {Er_LAgGVH: {hover: true}};

const serializationHash = "framer-RTTfF"

const variantClassNames = {Er_LAgGVH: "framer-v-6rvexy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({background, borderColor, height, hoverBackground, id, link, radius, width, ...props}) => { return {...props, hLScqekt6: background ?? props.hLScqekt6 ?? "var(--token-04c52171-4de3-44b2-8dbe-ce34f70099a7, rgb(31, 31, 31))", jxCehD3pc: borderColor ?? props.jxCehD3pc ?? {borderColor: "var(--token-a1e15573-407a-4e05-aad1-ccea5b623206, rgb(38, 38, 38)) /* {\"name\":\"Dark 15\"} */", borderStyle: "solid", borderWidth: 1}, NCzaX7UHr: radius ?? props.NCzaX7UHr ?? "10px", Nv8KA67oL: link ?? props.Nv8KA67oL, WCCllRI6z: hoverBackground ?? props.WCCllRI6z ?? "var(--token-a1e15573-407a-4e05-aad1-ccea5b623206, rgb(38, 38, 38))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;radius?: string;background?: string;hoverBackground?: string;borderColor?: Record<string, any>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Nv8KA67oL, NCzaX7UHr, hLScqekt6, WCCllRI6z, jxCehD3pc, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Er_LAgGVH", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={Nv8KA67oL} nodeId={"Er_LAgGVH"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-6rvexy", className, classNames)} framer-1m8wy88`} data-border data-framer-name={"Social Button"} layoutDependency={layoutDependency} layoutId={"Er_LAgGVH"} ref={ref ?? ref1} style={{"--border-bottom-width": `${jxCehD3pc.borderBottomWidth ?? jxCehD3pc.borderWidth}px`, "--border-color": jxCehD3pc.borderColor, "--border-left-width": `${jxCehD3pc.borderLeftWidth ?? jxCehD3pc.borderWidth}px`, "--border-right-width": `${jxCehD3pc.borderRightWidth ?? jxCehD3pc.borderWidth}px`, "--border-style": jxCehD3pc.borderStyle, "--border-top-width": `${jxCehD3pc.borderTopWidth ?? jxCehD3pc.borderWidth}px`, backgroundColor: hLScqekt6, borderBottomLeftRadius: radiusForCorner(NCzaX7UHr, 3), borderBottomRightRadius: radiusForCorner(NCzaX7UHr, 2), borderTopLeftRadius: radiusForCorner(NCzaX7UHr, 0), borderTopRightRadius: radiusForCorner(NCzaX7UHr, 1), ...style}} variants={{"Er_LAgGVH-hover": {backgroundColor: WCCllRI6z}}} {...addPropertyOverrides({"Er_LAgGVH-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-95tal2-container"} layoutDependency={layoutDependency} layoutId={"etlXv4b78-container"}><Material color={"rgb(102, 102, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"LinkedIn"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"etlXv4b78"} layoutId={"etlXv4b78"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RTTfF.framer-1m8wy88, .framer-RTTfF .framer-1m8wy88 { display: block; }", ".framer-RTTfF.framer-6rvexy { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 14px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-RTTfF .framer-95tal2-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 22px); position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-RTTfF.framer-6rvexy { gap: 0px; } .framer-RTTfF.framer-6rvexy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-RTTfF.framer-6rvexy > :first-child { margin-left: 0px; } .framer-RTTfF.framer-6rvexy > :last-child { margin-right: 0px; } }", ".framer-RTTfF[data-border=\"true\"]::after, .framer-RTTfF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"qR1Rg_gk2":{"layout":["auto","auto"]}}}
 * @framerVariables {"Nv8KA67oL":"link","NCzaX7UHr":"radius","hLScqekt6":"background","WCCllRI6z":"hoverBackground","jxCehD3pc":"borderColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDsk0RMEdx: React.ComponentType<Props> = withCSS(Component, css, "framer-RTTfF") as typeof Component;
export default FramerDsk0RMEdx;

FramerDsk0RMEdx.displayName = "Buttons/ Social Button";

FramerDsk0RMEdx.defaultProps = {height: 50, width: 48};

addPropertyControls(FramerDsk0RMEdx, {Nv8KA67oL: {title: "Link", type: ControlType.Link}, NCzaX7UHr: {defaultValue: "10px", title: "Radius", type: ControlType.BorderRadius}, hLScqekt6: {defaultValue: "var(--token-04c52171-4de3-44b2-8dbe-ce34f70099a7, rgb(31, 31, 31)) /* {\"name\":\"Dark 12\"} */", title: "Background", type: ControlType.Color}, WCCllRI6z: {defaultValue: "var(--token-a1e15573-407a-4e05-aad1-ccea5b623206, rgb(38, 38, 38)) /* {\"name\":\"Dark 15\"} */", title: "Hover Background", type: ControlType.Color}, jxCehD3pc: {defaultValue: {borderColor: "var(--token-a1e15573-407a-4e05-aad1-ccea5b623206, rgb(38, 38, 38)) /* {\"name\":\"Dark 15\"} */", borderStyle: "solid", borderWidth: 1}, title: "Border Color", type: ControlType.Border}} as any)

addFonts(FramerDsk0RMEdx, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})